import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
//import {withRouter} from 'react-router-dom';
import { compose } from 'react-apollo';
import Button from '@material-ui/core/Button';


const styles = theme => ({
 
});


class EnrollOfferTable extends React.Component {

  constructor(props) {
    super(props);
    this.state={
    }
  }

  renderCourse(c) {
   // const { match } = this.props;
   
    return (
      <TableRow key={c.key}>
        <TableCell>{c.period}</TableCell>
        <TableCell>{c.code + " - " +c.name}</TableCell>
        <TableCell>{c.place}</TableCell>
        <TableCell>{c.teacher}</TableCell>
        <TableCell>{c.first_period}</TableCell>
        <TableCell>
          <Button component={Link} to={"/enroll_spa/in/"+c.key}> Přihlásit </Button>
        </TableCell>
      </TableRow>
    )
  }

  renderSection(section,idx) {
    return (
      <React.Fragment key={idx}>
        <TableRow >
          <TableCell colSpan={1000}><Typography variant={"title"}>{section.name}</Typography></TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Termín</TableCell>
          <TableCell>Kurz</TableCell>
          <TableCell>Místo</TableCell>
          <TableCell>Lektor</TableCell>
          <TableCell>Zahájení</TableCell>
          <TableCell></TableCell>
        </TableRow>
        {section.courses.map(c=>{return this.renderCourse(c)})}
      </React.Fragment>
    )
  }
  render() {
    const { sections } = this.props;
    return (
      
         <Table>
           <TableBody>
              { sections.map((s,idx)=>{
                return this.renderSection(s,idx);
              })}
            </TableBody>
         </Table>
      
    );
  }
}

EnrollOfferTable.propTypes = {
  classes: PropTypes.object.isRequired,
  sections: PropTypes.array.isRequired,
  //match: PropTypes.object.isRequired,
};
EnrollOfferTable.defaultProps = {
 
};


export default compose(
//  withRouter,
  withStyles(styles),
)(EnrollOfferTable)

