import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { compose } from 'react-apollo'
import gql from 'graphql-tag';
import CircularProgress from '@material-ui/core/CircularProgress';
import EnrollOfferTable from './EnrollOfferTable';
import { Query } from "react-apollo";
//import Paper from '@material-ui/core/Paper';


const styles = theme => ({
  root: {
    width: '100%',
  },
});

const GET_ENROLL_SECTIONS = gql`
query EnrollSections($enroll_group:Int) {
  enrollSections(enroll_group:$enroll_group) {
    name
    folder {
      key
      name
    }
    courses {
      key
      evi_group
      folder_key
      season_key
      order_value
      name
      code
      active
      suspend
      cost_sa
      cost_sb
      cost_a
      cost_b
      cost_mode
      cost_sale
      accounting_year
      group_mode
      capacity
      card_line_1
      card_line_2
      period
      first_period
      teacher
      place
      comment
    }
  }
}
`;



class EnrollOffer extends React.Component {

  constructor(props) {
    super(props);
    this.state={
    }
  }


  render() {
    const { enroll_group } = this.props;
    return (
      
      <Query query={GET_ENROLL_SECTIONS} variables={{ enroll_group }} fetchPolicy={"network-only"} >
          {({ loading, error, data }) => {
            if (loading) return (<CircularProgress/>);
            if (error) return `Error!: ${error}`;
            return (
              <EnrollOfferTable 
                  sections={data.enrollSections} 
              />
            );
          }}
      </Query>
     
    );
  }
}

EnrollOffer.propTypes = {
  classes: PropTypes.object.isRequired,
  enroll_group: PropTypes.number.isRequired,
};
EnrollOffer.defaultProps = {
  
};


export default compose(
  withStyles(styles),
)(EnrollOffer)
