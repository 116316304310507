
import React from 'react';
import Typography from '@material-ui/core/Typography';



const directiveGDPR_SRO = (
    <React.Fragment>
        <Typography variant={"body1"} component="div">
            <ol type="I">
              <li>Základní ustanovení</li>
              <ol>
                <li>Správcem osobních údajů podle čl. 4 bod 7 nařízení Evropského parlamentu a Rady (EU) 2016/679 o ochraně fyzických osob v souvislosti se zpracováním osobních údajů a o volném pohybu těchto údajů (dále jen: „GDPR”) je <br/>Taneční škola STARLET s.r.o., IČO 06089445 
<br/>se sídlem Brno, Lipská 3, 616 00 (dále jen: „správce“).</li>
                <li>Kontaktní údaje správce jsou <br/>Adresa: Lipská 2442/3, 616 00 Brno <br/>e-mail: gdpr@starlet-brno.cz <br/>Telefon: +420 732 754 293</li>
                <li>Osobními údaji se rozumí veškeré informace o identifikované nebo identifikovatelné fyzické osobě; identifikovatelnou fyzickou osobou je fyzická osoba, kterou lze přímo či nepřímo identifikovat, zejména odkazem na určitý identifikátor, například jméno, identifikační číslo, lokační údaje, síťový identifikátor nebo na jeden či více zvláštních prvků fyzické, fyziologické, genetické, psychické, ekonomické, kulturní nebo společenské identity této fyzické osoby.</li>
                <li>Správce nejmenoval pověřence pro ochranu osobních údajů.</li>
              </ol>
              <li>Zdroje a kategorie zpracovávaných osobních údajů</li>
              <ol>
                <li>Správce zpracovává osobní údaje, které jste mu poskytl/a při podání přihlášky do kurzu.</li>
              </ol>
              <li>Zákonný důvod a účel zpracování osobních údajů</li>
              <ol>
                <li>Zákonným důvodem zpracování osobních údajů je:</li>
                <ul>
                  <li>plnění smlouvy mezi Vámi a správcem podle čl. 6 odst. 1 písm. b) GDPR</li>
                  <li>oprávněný zájem správce informovat žáky o zahájení a průběhu kurzů i akcích s kurzy souvisejícími</li>
                  <li>oprávněný zájem správce na ochraně majetku umístěného v prostorách, kde probíhají taneční kurzy</li>
                </ul>
                <li>Účelem zpracování osobních údajů je</li>
                <ul>
                  <li>vyřízení Vaší objednávky (přihlášení do kurzu) a výkon práv a povinností vyplývajících ze smluvního vztahu mezi Vámi a správcem (účetní dokumentace); při objednávce jsou vyžadovány osobní údaje, které jsou nutné pro úspěšné vyřízení objednávky (jméno a adresa, datum narození, kontakt), poskytnutí osobních údajů je nutným požadavkem pro uzavření a plnění smlouvy, bez poskytnutí osobních údajů není možné smlouvu uzavřít či jí ze strany správce plnit</li>
                  <li>zasílání informací týkajících se průběhu kurzů, změn v kurzech a v jejich průběhu, upozornění na pořádané akce, upozornění na vypsání nových kurzů a další marketingové aktivity přímo související s probíhajícími kurzy</li>
                </ul>
              </ol>
              <li>Doba uchovávání údajů</li>
              <ol>
                <li>Správce uchovává osobní údaje</li>
                <ul>
                  <li>po dobu nezbytnou k výkonu práv a povinností vyplývajících ze smluvního vztahu mezi Vámi a správcem a uplatňování nároků z těchto smluvních vztahů (po dobu 10 let od ukončení smluvního vztahu)</li>
                  <li>kontaktní údaje (e-mail, telefon) uchovává pro účely informování žáka o průběhu a změnách v kurzech, podávání informací o vypsání nových kurzů a pro účely přímého marketingu, nejdéle však po dobu 3 let</li>
                </ul>
                <li>Po uplynutí doby uchovávání osobních údajů správce osobní údaje vymaže, pokud jsou v papírové podobě, pak skartuje.</li>
              </ol>
              <li>Přístup k osobním údajů</li>
              <ol>
                <li>K osobním údajům mají přístup osoby</li>
                <ul>
                  <li>zajišťující služby bezprostředně se týkající pořádaných kurzů</li>
                  <li>zajišťující zpracování osobních a účetních údajů</li>
                  <li>zajišťující marketingové služby</li>
                </ul>
                <li>Správce nemá v úmyslu předávat osobní údaje třetím stranám.</li>
              </ol>
              <li>Vaše práva</li>
              <ol>
                <li>Za podmínek stanovených v GDPR máte</li>
                <ul>
                  <li>právo na přístup ke svým osobním údajům</li>
                  <li>právo opravy osobních údajů případně omezení zpracování</li>
                  <li>právo na výmaz osobních údajů</li>
                  <li>právo vznést námitku proti zpracování</li>
                  <li>právo na přenositelnost údajů</li>
                  <li>právo odvolat souhlas se zpracováním údajů písemně nebo elektronicky na adresu nebo email správce uvedený v čl. I těchto podmínek.</li>
                </ul>
                <li>Dále máte právo podat stížnost u Úřadu pro ochranu osobních údajů v případě, že se domníváte, že bylo porušeno Vaší právo na ochranu osobních údajů.</li>
              </ol>
              <li>Podmínky zabezpečení osobních údajů</li>
              <ol>
                <li>Správce prohlašuje, že přijal veškerá vhodná technická a organizační opatření k zabezpečení osobních údajů.</li>
                <li>Správce přijal technická opatření k zabezpečení datových úložišť a úložišť osobních údajů v listinné podobě. U dat v podobě elektronické správce zajistil přesně vymezený okruh osob, které mají k osobním datům přístup a je schopen prokázat, které osoby mají k datům přístup a v jakém rozsahu.</li>
                <li>Správce prohlašuje, že k osobním údajům mají přístup pouze jím pověřené osoby.</li>
              </ol>
              <li>Závěrečná ustanovení</li>
              <ol>
                <li>Odesláním přihlášky z internetového formuláře potvrzujete, že jste seznámen/a s podmínkami ochrany osobních údajů a že je v celém rozsahu přijímáte.</li>
                <li>Správce je oprávněn tyto podmínky změnit. Novou verzi podmínek ochrany osobních údajů zveřejní na svých internetových stránkách a zároveň Vám zašle novou verzi těchto podmínek na Vaši e-mailovou adresu, kterou jste správci poskytl/a.</li>
              </ol>
            </ol>
          </Typography>
          <Typography variant={"body1"} component="div">Tyto podmínky nabývají účinnosti dnem 25.5.2018.</Typography>
     </React.Fragment>
);


const directiveGDPR_LKB = (
    <React.Fragment>
              <Typography variant={"body1"} component="div">
            <ol type="I">
              <li>Základní ustanovení</li>
              <ol>
                <li>Správcem osobních údajů podle čl. 4 bod 7 nařízení Evropského parlamentu a Rady (EU) 2016/679 o ochraně fyzických osob v souvislosti se zpracováním osobních údajů a o volném pohybu těchto údajů (dále jen: „GDPR”) je <br/>
                  Mgr. Lucie Kokotková Buryanová, IČO 75688000  <br/>
                  se sídlem Moravské Knínice, U Jánečka 226, 664 34 (dále jen: „správce“).</li>
                <li>Kontaktní údaje správce jsou <br/>
                  Adresa: U Jánečka 226, 664 34 Moravské Knínice <br/>
                  e-mail: gdpr@starlet-brno.cz <br/>
                  Telefon: +420 732 754 293</li>
                <li>Osobními údaji se rozumí veškeré informace o identifikované nebo identifikovatelné fyzické osobě; identifikovatelnou fyzickou osobou je fyzická osoba, kterou lze přímo či nepřímo identifikovat, zejména odkazem na určitý identifikátor, například jméno, identifikační číslo, lokační údaje, síťový identifikátor nebo na jeden či více zvláštních prvků fyzické, fyziologické, genetické, psychické, ekonomické, kulturní nebo společenské identity této fyzické osoby.</li>
                <li>Správce nejmenoval pověřence pro ochranu osobních údajů.</li>
              </ol>
              <li>Zdroje a kategorie zpracovávaných osobních údajů</li>
              <ol>
                <li>Správce zpracovává osobní údaje, které jste mu poskytl/a při podání přihlášky do kurzu.</li>
              </ol>
              <li>Zákonný důvod a účel zpracování osobních údajů</li>
              <ol>
                <li>Zákonným důvodem zpracování osobních údajů je:</li>
                <ul>
                  <li>plnění smlouvy mezi Vámi a správcem podle čl. 6 odst. 1 písm. b) GDPR</li>
                  <li>oprávněný zájem správce informovat žáky o zahájení a průběhu kurzů i akcích s kurzy souvisejícími</li>
                  <li>oprávněný zájem správce na ochraně majetku umístěného v prostorách, kde probíhají taneční kurzy</li>
                </ul>
                <li>Účelem zpracování osobních údajů je</li>
                <ul>
                  <li>vyřízení Vaší objednávky (přihlášení do kurzu) a výkon práv a povinností vyplývajících ze smluvního vztahu mezi Vámi a správcem (účetní dokumentace); při objednávce jsou vyžadovány osobní údaje, které jsou nutné pro úspěšné vyřízení objednávky (jméno a adresa, datum narození, kontakt), poskytnutí osobních údajů je nutným požadavkem pro uzavření a plnění smlouvy, bez poskytnutí osobních údajů není možné smlouvu uzavřít či jí ze strany správce plnit</li>
                  <li>zasílání informací týkajících se průběhu kurzů, změn v kurzech a v jejich průběhu, upozornění na pořádané akce, upozornění na vypsání nových kurzů a další marketingové aktivity přímo související s probíhajícími kurzy</li>
                </ul>
              </ol>
              <li>Doba uchovávání údajů</li>
              <ol>
                <li>Správce uchovává osobní údaje</li>
                <ul>
                  <li>po dobu nezbytnou k výkonu práv a povinností vyplývajících ze smluvního vztahu mezi Vámi a správcem a uplatňování nároků z těchto smluvních vztahů (po dobu 10 let od ukončení smluvního vztahu)</li>
                  <li>kontaktní údaje (e-mail, telefon) uchovává pro účely informování žáka o průběhu a změnách v kurzech, podávání informací o vypsání nových kurzů a pro účely přímého marketingu, nejdéle však po dobu 3 let</li>
                </ul>
                <li>Po uplynutí doby uchovávání osobních údajů správce osobní údaje vymaže, pokud jsou v papírové podobě, pak skartuje.</li>
              </ol>
              <li>Přístup k osobním údajů</li>
              <ol>
                <li>K osobním údajům mají přístup osoby</li>
                <ul>
                  <li>zajišťující služby bezprostředně se týkající pořádaných kurzů</li>
                  <li>zajišťující zpracování osobních a účetních údajů</li>
                  <li>zajišťující marketingové služby</li>
                </ul>
                <li>Správce nemá v úmyslu předávat osobní údaje třetím stranám.</li>
              </ol>
              <li>Vaše práva</li>
              <ol>
                <li>Za podmínek stanovených v GDPR máte</li>
                <ul>
                  <li>právo na přístup ke svým osobním údajům</li>
                  <li>právo opravy osobních údajů případně omezení zpracování</li>
                  <li>právo na výmaz osobních údajů</li>
                  <li>právo vznést námitku proti zpracování</li>
                  <li>právo na přenositelnost údajů</li>
                  <li>právo odvolat souhlas se zpracováním údajů písemně nebo elektronicky na adresu nebo email správce uvedený v čl. I těchto podmínek.</li>
                </ul>
                <li>Dále máte právo podat stížnost u Úřadu pro ochranu osobních údajů v případě, že se domníváte, že bylo porušeno Vaší právo na ochranu osobních údajů.</li>
              </ol>
              <li>Podmínky zabezpečení osobních údajů</li>
              <ol>
                <li>Správce prohlašuje, že přijal veškerá vhodná technická a organizační opatření k zabezpečení osobních údajů.</li>
                <li>Správce přijal technická opatření k zabezpečení datových úložišť a úložišť osobních údajů v listinné podobě. U dat v podobě elektronické správce zajistil přesně vymezený okruh osob, které mají k osobním datům přístup a je schopen prokázat, které osoby mají k datům přístup a v jakém rozsahu.</li>
                <li>Správce prohlašuje, že k osobním údajům mají přístup pouze jím pověřené osoby.</li>
              </ol>
              <li>Závěrečná ustanovení</li>
              <ol>
                <li>Odesláním přihlášky z internetového formuláře potvrzujete, že jste seznámen/a s podmínkami ochrany osobních údajů a že je v celém rozsahu přijímáte.</li>
                <li>Správce je oprávněn tyto podmínky změnit. Novou verzi podmínek ochrany osobních údajů zveřejní na svých internetových stránkách a zároveň Vám zašle novou verzi těchto podmínek na Vaši e-mailovou adresu, kterou jste správci poskytl/a.</li>
              </ol>
            </ol>
          </Typography>
          <Typography variant={"body1"} component="div">Tyto podmínky nabývají účinnosti dnem 25.5.2018.</Typography>
    </React.Fragment>
);

const directiveGDPR_ZS = (
  <React.Fragment>
            <Typography variant={"body1"} component="div">
          <ol type="I">
            <li>Základní ustanovení</li>
            <ol>
              <li>Správcem osobních údajů podle čl. 4 bod 7 nařízení Evropského parlamentu a Rady (EU) 2016/679 o ochraně fyzických osob v souvislosti se zpracováním osobních údajů a o volném pohybu těchto údajů (dále jen: „GDPR”) je <br/>
                TK Starlet Brno z.s., IČO 099 61 232 <br/>
                se sídlem Lipská 2446/3, Brno, 616 00 (dále jen: „správce“).</li>
              <li>Kontaktní údaje správce jsou <br/>
                Adresa: Lipská 2442/3, 616 00 Brno <br/>
                e-mail: gdpr@starlet-brno.cz <br/>
                Telefon: +420 732 754 293</li>
              <li>Osobními údaji se rozumí veškeré informace o identifikované nebo identifikovatelné fyzické osobě; identifikovatelnou fyzickou osobou je fyzická osoba, kterou lze přímo či nepřímo identifikovat, zejména odkazem na určitý identifikátor, například jméno, identifikační číslo, lokační údaje, síťový identifikátor nebo na jeden či více zvláštních prvků fyzické, fyziologické, genetické, psychické, ekonomické, kulturní nebo společenské identity této fyzické osoby.</li>
              <li>Správce nejmenoval pověřence pro ochranu osobních údajů.</li>
            </ol>
            <li>Zdroje a kategorie zpracovávaných osobních údajů</li>
            <ol>
              <li>Správce zpracovává osobní údaje, které jste mu poskytl/a při podání přihlášky do kurzu.</li>
            </ol>
            <li>Zákonný důvod a účel zpracování osobních údajů</li>
            <ol>
              <li>Zákonným důvodem zpracování osobních údajů je:</li>
              <ul>
                <li>plnění smlouvy mezi Vámi a správcem podle čl. 6 odst. 1 písm. b) GDPR</li>
                <li>oprávněný zájem správce informovat žáky o zahájení a průběhu kurzů i akcích s kurzy souvisejícími</li>
                <li>oprávněný zájem správce na ochraně majetku umístěného v prostorách, kde probíhají taneční kurzy</li>
              </ul>
              <li>Účelem zpracování osobních údajů je</li>
              <ul>
                <li>vyřízení Vaší objednávky (přihlášení do kurzu) a výkon práv a povinností vyplývajících ze smluvního vztahu mezi Vámi a správcem (účetní dokumentace); při objednávce jsou vyžadovány osobní údaje, které jsou nutné pro úspěšné vyřízení objednávky (jméno a adresa, datum narození, kontakt), poskytnutí osobních údajů je nutným požadavkem pro uzavření a plnění smlouvy, bez poskytnutí osobních údajů není možné smlouvu uzavřít či jí ze strany správce plnit</li>
                <li>zasílání informací týkajících se průběhu kurzů, změn v kurzech a v jejich průběhu, upozornění na pořádané akce, upozornění na vypsání nových kurzů a další marketingové aktivity přímo související s probíhajícími kurzy</li>
              </ul>
            </ol>
            <li>Doba uchovávání údajů</li>
            <ol>
              <li>Správce uchovává osobní údaje</li>
              <ul>
                <li>po dobu nezbytnou k výkonu práv a povinností vyplývajících ze smluvního vztahu mezi Vámi a správcem a uplatňování nároků z těchto smluvních vztahů (po dobu 10 let od ukončení smluvního vztahu)</li>
                <li>kontaktní údaje (e-mail, telefon) uchovává pro účely informování žáka o průběhu a změnách v kurzech, podávání informací o vypsání nových kurzů a pro účely přímého marketingu, nejdéle však po dobu 3 let</li>
              </ul>
              <li>Po uplynutí doby uchovávání osobních údajů správce osobní údaje vymaže, pokud jsou v papírové podobě, pak skartuje.</li>
            </ol>
            <li>Přístup k osobním údajů</li>
            <ol>
              <li>K osobním údajům mají přístup osoby</li>
              <ul>
                <li>zajišťující služby bezprostředně se týkající pořádaných kurzů</li>
                <li>zajišťující zpracování osobních a účetních údajů</li>
                <li>zajišťující marketingové služby</li>
              </ul>
              <li>Správce nemá v úmyslu předávat osobní údaje třetím stranám.</li>
            </ol>
            <li>Vaše práva</li>
            <ol>
              <li>Za podmínek stanovených v GDPR máte</li>
              <ul>
                <li>právo na přístup ke svým osobním údajům</li>
                <li>právo opravy osobních údajů případně omezení zpracování</li>
                <li>právo na výmaz osobních údajů</li>
                <li>právo vznést námitku proti zpracování</li>
                <li>právo na přenositelnost údajů</li>
                <li>právo odvolat souhlas se zpracováním údajů písemně nebo elektronicky na adresu nebo email správce uvedený v čl. I těchto podmínek.</li>
              </ul>
              <li>Dále máte právo podat stížnost u Úřadu pro ochranu osobních údajů v případě, že se domníváte, že bylo porušeno Vaší právo na ochranu osobních údajů.</li>
            </ol>
            <li>Podmínky zabezpečení osobních údajů</li>
            <ol>
              <li>Správce prohlašuje, že přijal veškerá vhodná technická a organizační opatření k zabezpečení osobních údajů.</li>
              <li>Správce přijal technická opatření k zabezpečení datových úložišť a úložišť osobních údajů v listinné podobě. U dat v podobě elektronické správce zajistil přesně vymezený okruh osob, které mají k osobním datům přístup a je schopen prokázat, které osoby mají k datům přístup a v jakém rozsahu.</li>
              <li>Správce prohlašuje, že k osobním údajům mají přístup pouze jím pověřené osoby.</li>
            </ol>
            <li>Závěrečná ustanovení</li>
            <ol>
              <li>Odesláním přihlášky z internetového formuláře potvrzujete, že jste seznámen/a s podmínkami ochrany osobních údajů a že je v celém rozsahu přijímáte.</li>
              <li>Správce je oprávněn tyto podmínky změnit. Novou verzi podmínek ochrany osobních údajů zveřejní na svých internetových stránkách a zároveň Vám zašle novou verzi těchto podmínek na Vaši e-mailovou adresu, kterou jste správci poskytl/a.</li>
            </ol>
          </ol>
        </Typography>
        <Typography variant={"body1"} component="div">Tyto podmínky nabývají účinnosti dnem 16.3.2021.</Typography>
  </React.Fragment>
);


const directiveGeneral = (
    <React.Fragment>
        <Typography variant={"body2"}>ÚVODNÍ ZÁSADA:</Typography>
        <Typography variant={"body2"}>Přihláška je závazná, kurzovné se platí u zápisu a vrací se ve výjimečných případech. Hodiny zameškané vlastní vinou se nenahrazují.</Typography>
        <Typography variant={"body1"} component="div">
            <ol>
              <li>Kurzovné vracíme celé v případě, že ZÁSADNĚ nedodržíme smluvní podmínky:</li>
              <ul>
                <li>nedodržíme-li zásadně termín zahájení kurzu, který byl ZÁVAZNĚ sdělen klientovi. Zásadní nedodržení termínu zahájení se rozumí až čtyři týdny změny zahájení (dříve, později).</li>
                <li>Za závazné sdělení termínu zahájení se nerozumí termín uvedený na propagačních materiálech včetně webových stránek. Zde se jedná pouze o informativní sdělení pravděpodobného zahájení kurzu.</li>
                <li>zásadně změníme poplatek za kurz (zásadní změnou se rozumí zvýšení kurzovného více jak 20%)</li>
                <li>změna termínu průběhu kurzu. Pořadatel kurzů si vyhrazuje možnost nedodržení přesného klientem požadovaného termínu časového tj. např. 17:30, 20:00 hod. atd., pořadatel však musí dodržet klientem vybraný základní nebo náhradní den konání kurzu.</li>
              </ul>
              <li>Kurzovné vracíme v případě úmrtí, vážného onemocnění nebo přestěhování, které znemožňuje klientovi účast v kurzu. Kurzovné se vrací po odečtení zápisného a poplatku za absolvování části kurzu ode dne doručení doloženého výjimečného požadavku. Dokladem je míněno potvrzení lékaře o nemoci klienta, doklad o přestěhování atd. Změna školy v rámci Brna není důvodem k vrácení kurzovného.</li>
              <li>Při vrácení kurzovného se vždy odečítá zápisné, které je uvedeno v ceníku kurzů pro jednotlivé údobí (k dispozici v kanceláři TŠ).</li>
              <li>Vrácenou částku poukazujeme poštou nebo převodem na účet klienta, a to do čtrnácti dní od doručení zcela kompletní žádosti o vrácení kurzovného (tedy i s potřebnými doklady apod.)</li>
              <li>Poplatek za LETNÍ ČI ZIMNÍ ŠKOLU TANCE vracíme dle zvláštních směrnic. Odhlašuje-li se klient v průběhu kurzu, je mu vrácena pouze část kurzovného představující náklady na nevybranou stravu a nocležné.</li>
              <li>Žádost o vrácení kurzovného se podává písemně ideálně na předepsaném tiskopise, a to buď poštou, e-mailem nebo osobním předáním v naší kanceláři či kanceláři provozní (pokladna KC Babylon). Žádost musí být podána před nebo po zahájení kurzu, ne však po jeho ukončení.</li>
            </ol>
        </Typography>
        <Typography variant={"body1"} component="div">Směrnice platí pro TANEČNÍ ŠKOLU STARLET rodiny Buryanových od 1.10.2014.</Typography>
        <Typography variant={"body1"} component="div">Vypracoval Vladimír Buryan</Typography>
    </React.Fragment>
);

export {
    directiveGDPR_SRO,
    directiveGDPR_LKB,
    directiveGDPR_ZS,
    directiveGeneral
}