import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Link ,Redirect} from 'react-router-dom'
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import EnrollForm from './EnrollForm';
import Button from '@material-ui/core/Button';
import gql from "graphql-tag";
import { compose, withApollo } from "react-apollo";

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

import {directiveGDPR_SRO, directiveGDPR_LKB, directiveGDPR_ZS} from './Data/directives';
import FormGroup from '@material-ui/core/FormGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';


const styles = theme => ({
  root: {
    //marginBottom: theme.spacing.unit*2,
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
},

  divider: {
    marginTop:20
  },
  button: {
    margin: theme.spacing.unit
  },
  helparea: {
    margin: theme.spacing.unit
  },
  title: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    marginTop: theme.spacing.unit*2
  },
  wait: {
    margin: theme.spacing.unit
  },
  gdpr_box: {
    marginLeft: theme.spacing.unit,
    paddingTop: theme.spacing.unit*2,
  }
});



const ENROLL_STUDENT = gql`
mutation EnrollStudent($course_key:String!,$student:EnrollStudent!) {
  enrollStudent(course_key:$course_key,student:$student
   ) {
    ok
    public_key
  }
}`;

const ENROLL_PAIR = gql`
mutation EnrollPair($course_key:String!,$student1:EnrollStudent!,$student2:EnrollStudent!) {
  enrollPair(course_key:$course_key,student1:$student1,student2:$student2
   ) {
    ok
    public_key
  }
}`;




class EnrollCourse extends React.Component {

  constructor(props) {
    super(props);
    this.form1 = React.createRef();
    this.form2 = React.createRef();
    this.state = {
      done_id:null,
      valid1:false,
      valid2:false,
      after_submit:false,
      wait_submit:false,
      gdpr_value:false,
      gdpr_open:false,
    }
  }
  renderCostInfo() {
    const {course} = this.props;
    switch(course.cost_mode) {
      case "NORMAL": return (
        <React.Fragment>
          <Typography color="inherit" variant="body2"> Základní kurzovné: {course.cost_a}kč, pro studenty: {course.cost_b}kč. </Typography>
          {course.cost_sale &&(<Typography color="inherit" variant="body2"> POZOR NYNÍ SLEVA! Pokud se přihlásíte a zaplatíte v době slevy, platíte pouze:  {course.cost_sa}kč a studenti  {course.cost_sb}kč.</Typography>)}
        </React.Fragment>
      );
      case "PERIOD": return (
        <React.Fragment>
          <Typography color="inherit" variant="body2"> Celoroční kurzovné: {course.cost_a}kč. Pololetní {course.cost_b}kč. </Typography>
          {course.cost_sale &&(<Typography color="inherit" variant="body2"> POZOR NYNÍ SLEVA! Pokud se přihlásíte a zaplatíte v době slevy, platíte celoroční pouze:  {course.cost_sa}kč, pololetní {course.cost_sb}kč.</Typography>)}
        </React.Fragment>
      );
      case "FIX": return (
        <React.Fragment>
          <Typography color="inherit" variant="body2"> Kurzovné: {course.cost_a}kč. </Typography>
          {course.cost_sale &&(<Typography color="inherit" variant="body2"> POZOR NYNÍ SLEVA! Pokud se přihlásíte a zaplatíte v době slevy, platíte pouze:  {course.cost_sa}kč.</Typography>)}
        </React.Fragment>
      );
      default: return (
        <Typography color="inherit" variant="body2"> Informace o ceně kurzu není dostupná. </Typography>
      );
    }
  }
  renderCourse() {
    const {course} = this.props;
    const ci = this.renderCostInfo();
    return (
      <React.Fragment>
         {course.capacity_is_full && (<Typography color="error" variant="title">Pozor, kurz je již plně obsazen. Přihláška bude zařazena mezi náhradníky.</Typography>)}
         <Typography color="inherit" variant="body2">Místo: {course.place}</Typography>
         <Typography color="inherit" variant="body2">Lektor: {course.teacher}</Typography>
         <Typography color="inherit" variant="body2">Zahájení: {course.first_period}</Typography>
         {ci} 
      </React.Fragment>
    )
  }

  renderBottom() {
    return (
      <React.Fragment>
        <Typography color="inherit" variant="caption">Případné vrácení kurzovného se řídí směrnicí o vrácení kurzovného (v plném znění <Link to="/enroll_spa/directive">zde</Link>) a odesláním příhlášky potvrzuji, že jsem jí četl a souhlasím s ní. </Typography>
        <Typography color="inherit" variant="caption">Odesláním přihlášky souhlasím s používáním mých osobních údajů Taneční školou Starlet pro vnitřní administrativní účely a beru na vědomí, že budu Taneční školou Starlet pomocí mé e-mailové adresy informován o případných změnách v průběhu kurzu a o akcích, které se tanečního kurzu týkají. Dále beru na vědomí, že Taneční škola Starlet pořizuje na svých akcích fotografie, které mohou být použity v propagaci TŠ na webových stránkách nebo v tištěné propagaci. </Typography>
      </React.Fragment>
    )
  }
  onChangeForm1(valid) {
    if (this.form1.current) {
      //console.log("onchange FORM1 ref=",this.form1.current);
      this.setState({valid1:valid});
    }
  }

  onChangeForm2(valid) {
    if (this.form2.current) {
      //console.log("onchange FORM1 ref=",this.form2.current);
      this.setState({valid2:valid});
    }
  }
  isValid() {
    const {pair_mode} = this.props;
    const {valid1,valid2} = this.state;
    const gdpr = (!this.gdprAsk()) || this.state.gdpr_value;
    if (pair_mode) {
      return valid1 && valid2 && gdpr;
    } else {
      return valid1 && gdpr;
    }
  }

  onSubmit() {
    const {pair_mode,course} = this.props;
    if (!this.isValid()) {
      this.setState({after_submit:true});
    } else {
      if (pair_mode) {
        if (this.form1.current  && this.form2.current) {
          const doc1 = this.form1.current.readDoc();
          const doc2 = this.form2.current.readDoc();
          
          this.setState({wait_submit:true});
          this.props.client.mutate({
            mutation: ENROLL_PAIR,
            variables:{course_key:course.key,student1:doc1,student2:doc2},
          }).then(d=>{
            console.log("submit result",d)
            if (!d.data.enrollPair.ok) {
              alert("Chyba odesílnání přihlášek");
            }
            this.setState({wait_submit:false,done_id:d.data.enrollPair.public_key});
          }).catch(err=>{
            this.setState({wait_submit:false});
            alert("Chyba odesílnání přihlášek");
          })
        }
      } else {
        if (this.form1.current) {
          const doc1 = this.form1.current.readDoc();
          this.setState({wait_submit:true});
          this.props.client.mutate({
            mutation: ENROLL_STUDENT,
            variables:{course_key:course.key,student:doc1},
          }).then(d=>{
            console.log("submit result",d)
            if (!d.data.enrollStudent.ok) {
              alert("Chyba odesílnání přihlášky");
            }
            this.setState({wait_submit:false,done_id:d.data.enrollStudent.public_key});
          }).catch(err=>{
            this.setState({wait_submit:false});
            alert("Chyba odesílnání přihlášky");
          })
          
        }
      }
    }
  }
  gdprAsk() {
    const {course} = this.props;
    if (course.evi_group) {
      switch(course.evi_group) {
        case 'SRO': return true;
        case 'LB': return true;
        case 'ZS': return true;
        default:break;
      }
    }
    return false;
  }
  renderGDPR() {

    

    if (!this.gdprAsk()) {
      return null;
    }
 
    let directive=null;
    switch(this.props.course.evi_group) {
      case "SRO": directive=directiveGDPR_SRO; break;
      case "LB": directive=directiveGDPR_LKB; break;
      case "ZS": directive=directiveGDPR_ZS; break;
      default:
    }
 
    return (
      <Dialog open={this.state.gdpr_open} onClose={()=>this.setState({gdpr_open:false})}>
      <DialogTitle>Zásady ochrany osobních údajů a informace pro subjekt údajů</DialogTitle>
      <DialogContent >
        {directive}
      </DialogContent>
      <DialogActions>
        <Button onClick={()=>this.setState({gdpr_open:false})} color="primary">
          Zavřít
        </Button>
      </DialogActions>
    </Dialog>

    )
  }

  render() {
    const { classes, course,pair_mode,done_url,schools} = this.props;
    const { wait_submit , after_submit, done_id} = this.state;

    if (done_id) {
      return (
        <Redirect to={done_url+"/"+done_id} />
      )
    }

    let gdpr_ask = this.gdprAsk();


    if (!course.active) {
      return (
        <Typography color="inherit" variant="title"> Do kurzu není povolen online zápis. </Typography>
      )
    }
    const ci = this.renderCourse();
    const bi = this.renderBottom();
    const gdpr_dialog = this.renderGDPR();

    const warn = after_submit && !this.isValid();
   
    return (
      <React.Fragment>
      {ci} {gdpr_dialog}
      <Divider className={classes.divider}/>
      {pair_mode && (
        <Typography className={classes.title} variant="subheading">Nemáte-li partnera do kurzu, je nutné nás kontaktovat (starlet@starlet-brno.cz) - automaticky partnera nezajišťujeme.</Typography>
      )}


      <Typography className={classes.title} variant="title">Přihláška žáka {pair_mode && "- partner"}</Typography>
      <EnrollForm globalId="1" schools={schools} cost_mode={course.cost_mode} group_mode={course.group_mode}  showErrors={this.state.after_submit} innerRef={this.form1} onChange={(valid)=>this.onChangeForm1(valid)}/>

      {pair_mode && (
        <React.Fragment>
          <Typography className={classes.title} variant="title">Přihláška žáka {pair_mode && "- partnerka"}</Typography>
          <EnrollForm globalId="2" schools={schools} cost_mode={course.cost_mode} group_mode={course.group_mode}  showErrors={this.state.after_submit} innerRef={this.form2} onChange={(valid)=>this.onChangeForm2(valid)}/>
        </React.Fragment>
      )}

      {gdpr_ask && (
         <div className={classes.gdpr_box}>
          <Button variant="outlined" onClick={()=>this.setState({gdpr_open:true})}>zobrazit pravidla ochrany osobních údajů</Button>

          <FormGroup row>
            <FormControl required error={this.state.after_submit && this.state.gdpr_value==false}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.gdpr_value}
                  onChange={(e,v)=>this.setState({gdpr_value:v})}
                  value="checkedGDPR"
                  color="primary"
                />
              }
              label="Seznámil(a) jsem se s podmínkami ochrany osobních údajů"
            />
            <FormHelperText>Bez seznámení není možné odeslat přihlášku</FormHelperText>
            </FormControl>
          </FormGroup>
           </div>
      )}

      <div className={classes.container}>

        <Button className={classes.button} disabled={wait_submit} variant="outlined" color="primary" onClick={()=>this.onSubmit()}> Odeslat přihlášku </Button>
        {wait_submit && (
          <div className={classes.wait} ><CircularProgress /></div>
        )}
      </div>
      <Typography className={classes.helparea} color={warn?"error":"inherit"}>Pole označená (*) je třeba vyplnit</Typography>
      <Divider className={classes.divider}/>
      {bi}
      </React.Fragment>
    );
  }
}

EnrollCourse.propTypes = {
  client: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  course: PropTypes.object.isRequired,
  schools: PropTypes.arrayOf(PropTypes.object).isRequired,
  pair_mode: PropTypes.bool.isRequired,
  done_url: PropTypes.string.isRequired,
};
EnrollCourse.defaultProps = {
  
};


export default compose(
  withStyles(styles),
  withApollo,
)(EnrollCourse);

