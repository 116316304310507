
const setFolder = (folder_key) => ({
    type: 'SET_FOLDER',
    folder_key
})

const setSeason = (season_key) => ({
    type: 'SET_SEASON',
    season_key
})

const setSrchSeasons = (srch_season_keys) => ({
    type: 'SET_SRCH_SEASONS',
    srch_season_keys
})

const setPlaceIDForZone = (place_id) => ({
    type: 'SET_PLACEID_FOR_ZONE',
    place_id
})

const setPlaceIDForPricePlan = (place_id) => ({
    type: 'SET_PLACEID_FOR_PRICE_PLAN',
    place_id
})


const setZoneIDForSeat = (zone_id) => ({
    type: 'SET_ZONEID_FOR_SEAT',
    zone_id
})


const setZoneIDForZonePrice = (zone_id) => ({
    type: 'SET_ZONEID_FOR_ZONE_PRICE',
    zone_id
})

const setPlaceIDForZonePrice = (place_id) => ({
    type: 'SET_PLACEID_FOR_ZONE_PRICE',
    place_id
})

const setPricePlanIDForZonePrice = (price_plan_id) => ({
    type: 'SET_PRICEPLANID_FOR_ZONE_PRICE',
    price_plan_id
})



const setEviGroup = (evi_group) => ({
    type: 'SET_EVIGROUP',
    evi_group
})

const setAuth = (auth_token,auth_user) => ({
    type: 'SET_AUTH',
    auth_token, auth_user
})

const clearAuth = () => ({
    type: 'SET_AUTH_CLEAR'
    
})


const setErrorMessage = (message,expire) => ({
    type: 'SET_ERROR_MESSAGE',
    message,expire
})

const clearErrorMessage = () => ({
    type: 'CLEAR_ERROR_MESSAGE',
})

const setInfoMessage = (message,expire) => ({
    type: 'SET_INFO_MESSAGE',
    message,expire
})

const clearInfoMessage = () => ({
    type: 'CLEAR_INFO_MESSAGE',
})

const setNewVersion = () => ({
    type: 'SET_NEW_VERSION',
})

const setOffline = () => ({
    type: 'SET_OFFLINE',
})

const setOnline = () => ({
    type: 'SET_ONLINE',
})

const setDebugView = (visible) => ({
    type: 'SET_DEBUG_VIEW',
    visible
})

const setBookingRef = (booking_ref) => ({
    type: 'SET_BOOKING_REF',
    booking_ref
})


export {
    setAuth,
    clearAuth,
    setErrorMessage,
    clearErrorMessage,
    setInfoMessage,
    clearInfoMessage,
    setSeason,
    setFolder,
    setEviGroup,
    setNewVersion,
    setOffline,
    setOnline,
    setSrchSeasons,
    setPlaceIDForZone,
    setPlaceIDForPricePlan,
    setZoneIDForSeat,
    setZoneIDForZonePrice,
    setPlaceIDForZonePrice,
    setPricePlanIDForZonePrice, 
    setDebugView,
    setBookingRef
}