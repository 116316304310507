import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
//import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
//import FormLabel from '@material-ui/core/FormLabel';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';

import Lodash from 'lodash';
import { /*parseNumber as parsePhoneNumber,*/ isValidNumber as isValidPhoneNumber/*, formatNumber, AsYouType */} from 'libphonenumber-js'

const evalidator = require("email-validator");


const styles = theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },

   
    group: {
        margin: `${theme.spacing.unit}px 0`,
        height: "2.5em",
        paddingTop: "0.5em"
    },

    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: 200,
    },
    textFieldLong: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: 400,
    },
    textFieldExtraLong: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: 600,
    },
    textFieldShort: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: 100,
    },
    menu: {
       // width: 600,
        overflowX:"auto"
    },
});

function empty2null(v) {
    return v.length?v:null;
}

function yesno2bool(v) {
    switch(v) {
        case "yes": return true;
        case "no": return false;
        default: return null;
    }
}
function str2num(v) {
    if (v === "") { return null} 
    return parseInt(v,10);
}

class EnrollForm extends React.Component {

    state = {
        name: '',
        surname: '',
        sex: '',
        comment: '',
        student: '',
        phone: '',
        email: '',
        street: '',
        street_no: '',
        city: '',
        post_code:'',
        school: '',
        school_id: '',
        school_class: '',
        period: '',
        year: '',
        valid_name:true,
        valid_comment:true,
        valid_street:true,
        valid_street_no:true,
        valid_city:true,
        valid_post_code:true,
        valid_school:true,
        valid_school_class:true,
        valid_year:true
    };

    readDoc() {
        let school = empty2null(this.state.school);
        let school_id = null;
        if ((this.state.school_id != "?") && (this.state.school_id !='')) {
            const school_item = Lodash.find(this.props.schools,{id:this.state.school_id});
            if (school_item) {
                school_id = school_item.id;
                school = school_item.name;
            }
        }

        const doc = {
            name:empty2null(this.state.name),
            surname:this.state.surname,
            sex:this.state.sex,
            comment:empty2null(this.state.comment),
            student:yesno2bool(this.state.student),

            phone: this.state.phone,
            email: this.state.email,
            street: empty2null(this.state.street),
            street_no: empty2null(this.state.street_no),
            city: empty2null(this.state.city),
            post_code:empty2null(this.state.post_code),
            school: school,
            school_id: school_id,
            school_class: empty2null(this.state.school_class),
        
            long_period:yesno2bool(this.state.period),
            year:str2num(this.state.year)
        }
        return Lodash.omitBy(doc, Lodash.isNull);;
    }

    checkField(name,value) {
       // const {cost_mode} = this.props;
        switch(name) {
        case 'surname':
        case 'sex':
        case 'student':
        case 'period':
            return (value.length>0) && (value.length<200);    
        case 'email':
            return evalidator.validate(value);
        case 'phone':
            return isValidPhoneNumber(value,'CZ');
        case 'comment':
            return (value.length<1000);      
        default:
            return (value.length<200);
        }
    }
    isValid() {
        const {valid_surname,valid_sex, valid_student, valid_phone, valid_period, valid_email} = this.state;
        const {cost_mode} = this.props;
        if ((cost_mode == "PERIOD") && (!valid_period)) return false;
        return  (valid_surname && valid_sex && valid_student && valid_phone && valid_email);
    }

    showErrors() {
        this.setState({error:true});
    }


    handleChange = name => event => {
        let value = event.target.value;
        if (name==="year") {
            value = event.target.value.replace(/\D/g,'');
        }
        this.setState({
          [name]: value,
          ['valid_'+name]: this.checkField(name,value)
        },()=>{
            this.props.onChange(this.isValid()); 
        });
       
    };
    render() {
        const { classes,showErrors,globalId,cost_mode,group_mode,schools,native } = this.props;
        return (
            <form required noValidate autoComplete="off">
                {cost_mode == "PERIOD" && (
                    <div className={classes.container}>
                        <FormControl error={showErrors && !this.state.valid_period} margin="normal" component="fieldset" required className={classes.textFieldLong}>
                            <InputLabel shrink htmlFor={"period"+globalId}>Typ kurzovného</InputLabel>
                            <RadioGroup
                                id={"period"+globalId}
                                row
                                aria-label={"period"+globalId}
                                name="period"
                                className={classes.group}
                                value={this.state.period}
                                onChange={this.handleChange('period')}
                            >
                                <FormControlLabel value="yes" control={<Radio color="primary"/>} label="celoroční" />
                                <FormControlLabel value="no" control={<Radio color="primary"/>} label="pololetní" />
                            </RadioGroup>
                           {/* <FormHelperText id={"period"+globalId}>povinná položka</FormHelperText> */}
                        </FormControl>
                    </div>
                )}


                <div className={classes.container}>
                <TextField
                    error={showErrors && !this.state.valid_name}
                    id={"name"+globalId}
                    label="Jméno"
                    className={classes.textFieldShort}
                    value={this.state.name}
                    onChange={this.handleChange('name')}
                    margin="normal"
                />

                <TextField
                    required
                    error={showErrors && !this.state.valid_surname}
                    id={"surname"+globalId}
                    label="Přijmení"
                    className={classes.textField}
                    value={this.state.surname}
                    onChange={this.handleChange('surname')}
                    margin="normal"
                    //helperText="povinná položka"
                />
   
                <FormControl error={showErrors && !this.state.valid_sex} margin="normal" component="fieldset" required className={classes.textField}>
                        <InputLabel shrink htmlFor={"gender"+globalId}>Pohlaví</InputLabel>
                        <RadioGroup
                            id={"gender"+globalId}
                            row
                            aria-label={"gender"+globalId}
                            name="gender"
                            className={classes.group}
                            value={this.state.sex}
                            onChange={this.handleChange('sex')}
                        >
                            <FormControlLabel value="FEMALE" control={<Radio color="primary"/>} label="Žena" />
                            <FormControlLabel value="MALE" control={<Radio color="primary"/>} label="Muž" />
                        </RadioGroup>
                        {/*<FormHelperText id={"gender"+globalId}>povinná položka</FormHelperText>*/}
                </FormControl>
   
                <FormControl error={showErrors && !this.state.valid_student} margin="normal" component="fieldset" required className={classes.textField}>
                        <InputLabel shrink htmlFor={"student"+globalId}>Student</InputLabel>
                        <RadioGroup
                            id={"student"+globalId}
                            row
                            aria-label={"student"+globalId}
                            name="student"
                            className={classes.group}
                            value={this.state.student}
                            onChange={this.handleChange('student')}
                        >
                            <FormControlLabel value="yes" control={<Radio color="primary"/>} label="Ano" />
                            <FormControlLabel value="no" control={<Radio color="primary"/>} label="Ne" />
                        </RadioGroup>
                        {/*<FormHelperText id={"student"+globalId}>povinná položka</FormHelperText>*/}
                </FormControl>
   
   
                <TextField
                    error={showErrors && !this.state.valid_year}
                    id={"year"+globalId}
                    label="Rok narození"
                    className={classes.textFieldShort}
                    value={this.state.year}
                    onChange={this.handleChange('year')}
                    margin="normal"
                />
              

                </div>
                <div className={classes.container}>
 
 
                <TextField
                    required
                    error={showErrors && !this.state.valid_phone}
                    id={"phone"+globalId}
                    label="Telefon"
                    className={classes.textField}
                    value={this.state.phone}
                    onChange={this.handleChange('phone')}
                    margin="normal"
                    //helperText="povinná položka"
                />
 
                <TextField
                    required
                    error={showErrors && !this.state.valid_email}
                    id={"email"+globalId}
                    label="E-mail"
                    className={classes.textFieldLong}
                    value={this.state.email}
                    onChange={this.handleChange('email')}
                    margin="normal"
                    //helperText="povinná položka"
                />
                </div>

                {group_mode == "SCHOOL" && (
                    <div className={classes.container}> 



                        <TextField
                            id={"school_id"+globalId}
                            select
                            label="Škola"
                            
                            className={classes.textFieldExtraLong}
                            value={this.state.school_id}
                            onChange={this.handleChange('school_id')}
                            SelectProps={{
                                native:native,
                                MenuProps: {
                                className: classes.menu,
                                },
                            }}
                            helperText='Není-li škola v seznamu, zvol "Jiná škola"'
                            margin="normal"
                            >
                            {native && (
                                <React.Fragment>
                                <option key={"?x"} value={""}>
                                    {""}
                                </option>
                                <option key={"?"} value={"?"}>
                                    {" --- Jiná škola --- "}
                                </option>
                                {schools.map(option => (
                                    <option key={option.id} value={option.id}>
                                    {option.name}
                                    </option>
                                ))}
                                </React.Fragment>
                            )}
                            {!native && (
                                <React.Fragment>
                                <MenuItem key={"?"} value={"?"}>
                                    {" --- Jiná škola --- "}
                                </MenuItem>
                                {schools.map(option => (
                                    <MenuItem key={option.id} value={option.id}>
                                    {option.name}
                                    </MenuItem>
                                ))}
                                </React.Fragment>
                            )}
                        </TextField>
                        { (this.state.school_id == "?") && (
                            <TextField
                                error={showErrors && !this.state.valid_school}
                                id={"school"+globalId}
                                label="Škola (neuvedená v seznamu)"
                                className={classes.textFieldLong}
                                value={this.state.school}
                                onChange={this.handleChange('school')}
                                margin="normal"
                                helperText='Byla vybrána "Jiná škola", jméno školy uveďte do tohoto pole'
                            />
                        )}        
 
                        <TextField
                            error={showErrors && !this.state.valid_school}
                            id={"school_class"+globalId}
                            label="Třída"
                            className={classes.textFieldShort}
                            value={this.state.school_class}
                            onChange={this.handleChange('school_class')}
                            margin="normal"
                        />

                    </div>
                )}



                <div className={classes.container}> 

                <TextField
                    error={showErrors && !this.state.valid_street}
                    id={"street"+globalId}
                    label="Ulice"
                    className={classes.textField}
                    value={this.state.street}
                    onChange={this.handleChange('street')}
                    margin="normal"
                />
        
                <TextField
                    error={showErrors && !this.state.valid_street_no}
                    id={"street_no"+globalId}
                    label="Č.p."
                    className={classes.textFieldShort}
                    value={this.state.street_no}
                    onChange={this.handleChange('street_no')}
                    margin="normal"
                />
                
                <TextField
                    error={showErrors && !this.state.valid_city}
                    id={"city"+globalId}
                    label="Město"
                    className={classes.textField}
                    value={this.state.city}
                    onChange={this.handleChange('city')}
                    margin="normal"
                />

                <TextField
                    error={showErrors && !this.state.valid_post_code}
                    id={"post_code"+globalId}
                    label="PSČ"
                    className={classes.textFieldShort}
                    value={this.state.post_code}
                    onChange={this.handleChange('post_code')}
                    margin="normal"
                />

                </div>


                <div className={classes.container}>
                <TextField
                    error={showErrors && !this.state.valid_comment}
                    id={"comment"+globalId}
                    label="Poznámka"
                    className={classes.textFieldLong}
                    value={this.state.comment}
                    onChange={this.handleChange('comment')}
                    margin="normal"
                />
                </div>

            </form>
        )
    };
};

EnrollForm.propTypes = {
    classes: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    showErrors: PropTypes.bool,
    globalId: PropTypes.string,
    cost_mode: PropTypes.oneOf(["NORMAL","PERIOD","FIX"]).isRequired,
    group_mode: PropTypes.oneOf(["SCHOOL","PAIR","SINGLE"]).isRequired,
    schools: PropTypes.arrayOf(PropTypes.object).isRequired,
    native: PropTypes.bool,
};
EnrollForm.defaultProps = {
    showErrors:false,
    globalId:"1",
    native:true,
};
  
export default withStyles(styles)(EnrollForm);