
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router';
import { compose, graphql } from 'react-apollo';
import { Route, Switch } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Version from "./Version";
import CssBaseline from '@material-ui/core/CssBaseline';
import EnrollOffer from './EnrollOffer';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import MapIcon from '@material-ui/icons/Place';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import Hidden from '@material-ui/core/Hidden';
import gql from 'graphql-tag';
import CircularProgress from '@material-ui/core/CircularProgress';
//import EnrollCourse from './EnrollCourse';
import { Query } from "react-apollo";
import EnrollCourse from './EnrollCourse';

import { setBookingRef } from './../actions'
import { connect } from 'react-redux'

import {directiveGDPR_SRO, directiveGDPR_LKB, directiveGDPR_ZS, directiveGeneral} from './Data/directives';


const GET_ORDER = gql`
query Order($public_key:String!) {
  order(public_key:$public_key) {
    ok
    type
    student {
      status
      name
      surname
      sex
      email
      course_cost
    }
    students {
      status
      name
      surname
      sex
      email
      course_cost
    }
    created_at
  }
}`;

const GET_ENROLL_COURSE = gql`
query EnrollCourse($course_key:String!) {
  course(key:$course_key) {
    key
    evi_group
    folder_key
    season_key
    capacity_is_full
    order_value
    name
    code
    active
    suspend
    cost_sa
    cost_sb
    cost_a
    cost_b
    cost_mode
    cost_sale
    accounting_year
    group_mode
    capacity
    card_line_1
    card_line_2
    period
    first_period
    teacher
    place
    comment
  }
  schools {
    id
    name
  }
}`;


const GET_BOOKING_REF = gql`
mutation GenerateBookingRef {
  generateBookingRef
}`;


const styles = theme => ({
  root: {
    flexGrow: 1,
    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing.unit*12,
      marginRight: theme.spacing.unit*12,
    },
    marginTop: theme.spacing.unit*2,
    marginBottom: theme.spacing.unit
  },

  button_root: {
    minHeight:24,
    //padding:0,
    lineHeight: 0
  },

  button_size: {
    minHeight:24,
    //padding:0
    minWidth:200,
  },

  bar: {
    flex:1,
    display: "flex",
    color: "white",
    backgroundColor: "black",
    minHeight:50,
    paddingTop:15,
    paddingBottom:10,
    paddingLeft:10,
    paddingRight:20
  },

  bottom_bar: {
    flex:"1 1 auto",
    color: "white",
    backgroundColor: "black",
    minHeight:50,
    paddingTop:15,
    paddingBottom:20,
  },

  logo: {
    margin:20,
  },

  content: {
    padding:theme.spacing.unit*2,
    minHeight: 200
  },
  header: { 
    display: "flex",
    paddingLeft: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    marginBottom: theme.spacing.unit
  },
  header_t: {
    paddingLeft: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
  },
  flex: {
    flex: 1,
  },
  title: {
   // marginLeft: theme.spacing.unit,
   // marginRight: theme.spacing.unit,
    marginTop: theme.spacing.unit*2
  },
});

/* eslint-disable react/prop-types */

const PageNoMatch = withStyles(styles)(({match,classes}) => (
  <React.Fragment>
      <Grid item xs={12} className={classes.bar} >
          <Typography className={classes.header_t} color="inherit" variant="title">Stránka nenalezena</Typography>
      </Grid>
      <Grid item xs={12} className={classes.content}>   
          <Button component={Link} to={"/enroll_spa"}> Přejít na nabídku kurzů </Button>
      </Grid>
  </React.Fragment>
));


const PageDirective = withStyles(styles)(({match,classes}) => (
  <React.Fragment>
      <Grid item xs={12} className={classes.bar} >
          <Typography className={classes.header_t} color="inherit" variant="title">Směrnice o vrácení kurzovného</Typography>
          <div className={classes.flex}/> 
          <Button classes={{
            root: classes.button_root, 
            sizeSmall: classes.button_size, 
          }} size="small" variant="raised" component={Link} to={"/enroll_spa"}> Zpět na nabídku kurzů </Button>
      </Grid>
      <Grid item xs={12} className={classes.content}>   
          {directiveGeneral}
      </Grid>
  </React.Fragment>
));



const PageGDPR_SRO = withStyles(styles)(({match,classes}) => (
  <React.Fragment>
      <Grid item xs={12} className={classes.bar} >
          <Typography className={classes.header_t} color="inherit" variant="title">Zásady ochrany osobních údajů a informace pro subjekt údajů</Typography>
          <div className={classes.flex}/> 
          <Button classes={{
            root: classes.button_root, 
            sizeSmall: classes.button_size, 
          }} size="small" variant="raised" component={Link} to={"/enroll_spa"}> Zpět na nabídku kurzů </Button>
      </Grid>
      <Grid item xs={12} className={classes.content}>   
          {directiveGDPR_SRO}
      </Grid>
  </React.Fragment>
));


const PageGDPR_LKB = withStyles(styles)(({match,classes}) => (
  <React.Fragment>
      <Grid item xs={12} className={classes.bar} >
          <Typography className={classes.header_t} color="inherit" variant="title">Zásady ochrany osobních údajů a informace pro subjekt údajů</Typography>
          <div className={classes.flex}/> 
          <Button classes={{
            root: classes.button_root, 
            sizeSmall: classes.button_size, 
          }} size="small" variant="raised" component={Link} to={"/enroll_spa"}> Zpět na nabídku kurzů </Button>
      </Grid>
      <Grid item xs={12} className={classes.content}>   
        {directiveGDPR_LKB}    
      </Grid>
  </React.Fragment>
));

const PageGDPR_ZS = withStyles(styles)(({match,classes}) => (
  <React.Fragment>
      <Grid item xs={12} className={classes.bar} >
          <Typography className={classes.header_t} color="inherit" variant="title">Zásady ochrany osobních údajů a informace pro subjekt údajů</Typography>
          <div className={classes.flex}/> 
          <Button classes={{
            root: classes.button_root, 
            sizeSmall: classes.button_size, 
          }} size="small" variant="raised" component={Link} to={"/enroll_spa"}> Zpět na nabídku kurzů </Button>
      </Grid>
      <Grid item xs={12} className={classes.content}>   
        {directiveGDPR_ZS}    
      </Grid>
  </React.Fragment>
));


const PageEnrollTable = withStyles(styles)(({match,classes}) => {

  let eg = 0;
    
  if (match.params.group) {
      eg = parseInt(match.params.group,10);
  }



  return (
    <React.Fragment>
      <Grid item xs={12} className={classes.bar} >
          <Typography className={classes.header_t} color="inherit" variant="title">Volba kurzu pro online přihlášku</Typography>
      </Grid>
      <Grid item xs={12} className={classes.content}>
        <div style={{overflowX:"auto"}}>   
        <EnrollOffer enroll_group={eg} />
        </div>
      </Grid>
    </React.Fragment>
)});

function safenull(s) {
  if (s) return s;
  return "";
}

function status2info(s) {
  switch (s) {
    case 'FILED': return "řádně podaná. Abyste mohli být zařazeni do vybraného kurzu, je třeba uhradit kurzovné";
    case 'SPARE': return "zařazena mezi náhradníky";
    default: return "? - kontaktuje nás";
  }
}

const PageEnrollDone = withStyles(styles)(({match,classes}) => {
  const public_key = match.params.enroll_id;

  return (
    <Query query={GET_ORDER} variables={{ public_key }} fetchPolicy={"network-only"} >
          {({ loading, error, data }) => {
            if (loading) return (<CircularProgress/>);
            if (error) return (
              <React.Fragment>
              <Grid item xs={12} className={classes.bar} >
                <Typography className={classes.header_t} color="inherit" variant="title">{"Informace o podané přihlášce nejsou dostupné"}</Typography>
                <div className={classes.flex}/> 
                <Button classes={{
                  root: classes.button_root, 
                  sizeSmall: classes.button_size, 
                }} size="small" variant="raised" component={Link} to={"/enroll_spa"}> Zpět na nabídku kurzů </Button>
              </Grid>
              <Grid item xs={12} className={classes.content}>
                <Typography> kontaktujte nás </Typography>
              </Grid>
            </React.Fragment>
            );
            const ok = ((data.order) && (data.order.ok));
            let info = null;
            if (ok) {
              if (data.order.type == "STUDENT") {
                const st = data.order.student;
                if (st) {
                info = (<React.Fragment>
                  <Typography className={classes.title} variant="title">Přihláška žáka</Typography>
                  <Typography> Jméno a přijmení: {safenull(st.name)+" "+safenull(st.surname)} </Typography>
                  <Typography> E-mail: {st.email} </Typography>
                  <Typography> Stav přihlášky: {status2info(st.status)} </Typography>
                  <Typography> Kurzovné: {st.course_cost} </Typography>
                  {st.status == "FILED" && (<Typography> Na uvedený e-mail Vám byly odeslány instrukce k platbě a další informace. </Typography>)}
                  {st.status == "SPARE" && (<Typography> Na uvedený e-mail Vám bylo odesláno potvrzení o přijetí přihlášky mezi náhradníky. </Typography>)}
                </React.Fragment>)
                }
              } else if (data.order.type == "PAIR") {
                info = "info pair -  TODO";
                const st1 = data.order.students[0];
                const st2 = data.order.students[1];
                if (st1 && st2) {
                  info = (<React.Fragment>

                    <Typography className={classes.title} variant="title">Přihláška žáka - partner</Typography>


                    <Typography> Jméno a přijmení: {safenull(st1.name)+" "+safenull(st1.surname)} </Typography>
                    <Typography> E-mail: {st1.email} </Typography>
                    <Typography> Stav přihlášky: {status2info(st1.status)} </Typography>
                    <Typography> Kurzovné: {st1.course_cost} </Typography>
                    {st1.status == "FILED" && (<Typography> Na uvedený e-mail Vám byly odeslány instrukce k platbě a další informace. </Typography>)}
                    {st1.status == "SPARE" && (<Typography> Na uvedený e-mail Vám bylo odesláno potvrzení o přijetí přihlášky mezi náhradníky. </Typography>)}
 
                    <Typography className={classes.title} variant="title">Přihláška žáka - partnerka</Typography>

                    <Typography> Jméno a přijmení: {safenull(st2.name)+" "+safenull(st2.surname)} </Typography>
                    <Typography> E-mail: {st2.email} </Typography>
                    <Typography> Stav přihlášky: {status2info(st2.status)} </Typography>
                    <Typography> Kurzovné: {st2.course_cost} </Typography>
                    {st2.status == "FILED" && (<Typography> Na uvedený e-mail Vám byly odeslány instrukce k platbě a další informace. </Typography>)}
                    {st2.status == "SPARE" && (<Typography> Na uvedený e-mail Vám bylo odesláno potvrzení o přijetí přihlášky mezi náhradníky. </Typography>)}
 
                  </React.Fragment>)
                }
              }
            }
            return (
              <React.Fragment>
                <Grid item xs={12} className={classes.bar} >
                  <Typography className={classes.header_t} color="inherit" variant="title">{ok?"Přihláška do kurzu byla odeslána":"Nastal problém s odesláním přihlášky"}</Typography>
                  <div className={classes.flex}/> 
                  <Button classes={{
                    root: classes.button_root, 
                    sizeSmall: classes.button_size, 
                  }} size="small" variant="raised" component={Link} to={"/enroll_spa"}> Zpět na nabídku kurzů </Button>
                </Grid>
                <Grid item xs={12} className={classes.content}>
                  { ok && (
                    <React.Fragment>
                       {info}
                    </React.Fragment>
                    )}
                  { !ok && (<Typography> omlouváme se, zkuste přihlášku poslat ještě jednou, případně nás kontaktujte. </Typography>)}
                </Grid>
              </React.Fragment>
            );
          }}

    </Query>
  )
});


const PageEnrollCourse = withStyles(styles)(({match,classes}) => {

  const course_key = match.params.course_key;
    

  return (
      <Query query={GET_ENROLL_COURSE} variables={{ course_key }} fetchPolicy={"network-only"} >
          {({ loading, error, data }) => {
            if (loading) return (<CircularProgress/>);
            if (error) return `Error!: ${error}`;
            return (
              <React.Fragment>
                <Grid item xs={12} className={classes.bar} >
                  <Typography className={classes.header_t} color="inherit" variant="title">{data.course?"Zápis do kurzu "+data.course.code+" - "+data.course.name:"Kurz neexistuje"}</Typography>
                  
                  <Hidden smDown>
                  <div className={classes.flex}/> 
                  <Button classes={{
                    root: classes.button_root, 
                    sizeSmall: classes.button_size, 
                  }} size="small" variant="raised" component={Link} to={"/enroll_spa"}> Zpět na nabídku kurzů </Button>
                  </Hidden>
                </Grid>
                <Grid item xs={12} className={classes.content}>
                  {data.course && (<EnrollCourse done_url={"/enroll_spa/done"} course={data.course} schools={data.schools} pair_mode={data.course.group_mode=="PAIR"} />)}
                </Grid>
              </React.Fragment>
            );
          }}
      </Query>
)});


/* eslint-enable react/prop-types */

class EnrollApp extends React.Component {

  constructor(props) {
    super(props);
  }
  isValidBookingRef() {
    if (this.props.booking_ref) {
      if (this.props.booking_ref.length>0) {
        return true;
      }
    }
    return false;
  }
  
  componentDidMount() {
    //fetch booking ref
    console.log("EnrollApp.componentDidMount, current booking_ref",this.props.booking_ref, "date",this.props.last_booking_date);
    if (this.isValidBookingRef()) {
      console.log("booking_ref is valid");
    } else {
      console.log("invalid booking_ref");
      this.props.generateBookingRef({}).then(res=>{
        console.log("new booking ref",res);
        if (res.data && res.data.generateBookingRef) {
          this.props.onSetBookingRef(res.data.generateBookingRef);
        } else {
          console.error("can't get new booking ref, mutation result is empty");
        }
      }).catch(err=>{
        console.error("can't get new booking ref",err);
      });
    }
  }
 

  render() {
    console.log("MASTER RENDER");
    const {classes} = this.props;
    return (
      <React.Fragment>
        <CssBaseline/>
        <Version short/>
        <div className={classes.root}>
        <Grid container spacing={0}>
          <Grid className={classes.header} item xs={12}>

              <MapIcon/><Typography className={classes.header_t} variant="body2">Špitálka 253/6, 602 00 Brno–Zábrdovice</Typography>
              <PhoneIcon/><Typography className={classes.header_t} variant="body2">+420 732 754 293</Typography>
              <a style={{display:"flex", color:"unset"}} href={"mailto:starlet@starlet-brno.cz"}>
                <EmailIcon/><Typography className={classes.header_t} variant="body2">starlet@starlet-brno.cz</Typography>
              </a>
              <div className={classes.flex}/> 
              <a href={"https://www.facebook.com/tsstarlet"}>
                <svg style={{width:24,height:24}} viewBox={"0 0 24 24"}>
                  <path fill={"#000000"} d={"M17,2V2H17V6H15C14.31,6 14,6.81 14,7.5V10H14L17,10V14H14V22H10V14H7V10H10V6A4,4 0 0,1 14,2H17Z"}/>
                </svg>
              </a>
          
          </Grid>
          <Grid item xs={12}>
            
            <Paper >
           
              <Grid container spacing={0}>

                <Grid item md={6} xs={12}>
                  <img className={classes.logo} src={"/images/enroll_logo.png"} /> 
                </Grid>
                <Grid item md={6} xs={12}>
                  <Grid container style={{height:"100%"}}spacing={0} alignItems="center" justify="center">
                    <Grid className={classes.header} item>
                      <Typography className={classes.header_t} variant="title">MÁTE DOTAZ?</Typography>
                    </Grid>
                    <Grid className={classes.header} item>
                      <PhoneIcon/> <Typography className={classes.header_t} variant="title">+420 732 754 293</Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Switch>
                  <Route path="/enroll_spa/directive" component={PageDirective}/>
                  <Route path="/enroll_spa/gdpr_sro" component={PageGDPR_SRO}/>
                  <Route path="/enroll_spa/gdpr_lkb" component={PageGDPR_LKB}/>
                  <Route path="/enroll_spa/gdpr_zs" component={PageGDPR_ZS}/>
                  <Route path="/enroll_spa/done/:enroll_id" component={PageEnrollDone}/>
                  <Route path="/enroll_spa/in/:course_key" component={PageEnrollCourse}/>
                  <Route path="/enroll_spa/:group(\d+)" component={PageEnrollTable}/>
                  <Route exact path="/enroll_spa/" component={PageEnrollTable}/>
                  <Route component={PageNoMatch}/>
                </Switch>
      
                <Grid item xs={12} className={classes.bottom_bar} >
                  <Typography className={classes.header_t} color="inherit" variant="body1">Kontaktní telefon: 732 754 293, 545 162 090 a e-mail: starlet@starlet-brno.cz</Typography>
                  <Typography className={classes.header_t} color="inherit" variant="body1">Přihlášky a informace: Kancelář TŠ, Špitálka 6 (po-pá 9:00-15:00)</Typography>
                  <Typography className={classes.header_t} color="inherit" variant="body1">Copyright © 2018 Taneční škola Starlet Brno</Typography>
                </Grid>
                <Grid item xs={12}>
                </Grid>
              </Grid>
            </Paper>
           
          </Grid>
        </Grid>
        </div>
      </React.Fragment>
    );
  }
  
}

EnrollApp.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  booking_ref: PropTypes.string,
  last_booking_date: PropTypes.string,
  generateBookingRef: PropTypes.func.isRequired,
  onSetBookingRef: PropTypes.func.isRequired
};
EnrollApp.defaultProps = {
  
};

function mapStateToProps(state) {
    return { 
        booking_ref: state.booking_ref,
        last_booking_date: state.last_booking_date
    }
  }
    
  const mapDispatchToProps = dispatch => {
    return {
      onSetBookingRef: ref => {
        dispatch(setBookingRef(ref))
      },
    }
  }


export default withRouter(compose(
  withStyles(styles),
  graphql(GET_BOOKING_REF,{
    name: "generateBookingRef",
  }),
  connect(mapStateToProps,mapDispatchToProps)
)(EnrollApp));

