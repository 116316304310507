import Moment from 'moment';

/*
function def_expire(exp) {
  if (exp) { 
    return Moment(exp).toISOString();
  } else {
    return Moment().add(10,"seconds").toISOString();
  }
}
*/

const client = (state={booking_ref:null,last_booking_date:null}, action) => {
    switch (action.type) {
      case 'SET_BOOKING_REF':
        console.log("SET_BOOKING_REF",action)
        return  Object.assign({}, state, {
          booking_ref: action.booking_ref,
          last_booking_date: Moment().toISOString()
        })
        case 'SET_LAST_BOOKING_DATE':
        console.log("SET_LAST_BOOKING_DATE",action)
        return  Object.assign({}, state, {
          last_booking_date: Moment(action.last_booking_date).toISOString(),
        })
        default:
        return state
    }
  }
  
  export default client;