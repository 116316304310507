import React from 'react';
import { render } from 'react-dom';
import { ApolloProvider } from 'react-apollo';
import { ApolloClient } from 'apollo-client';
import { HttpLink } from 'apollo-link-http';
import { InMemoryCache, defaultDataIdFromObject } from 'apollo-cache-inmemory';
import { BrowserRouter } from 'react-router-dom';
import version from './../version.json';
import EnrollApp from './components/EnrollApp';
import Raven from 'raven-js';

//import LogRocket from 'logrocket';

import {store,persistor} from './store_client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';


console.log("NODE_ENV",process.env.NODE_ENV);

if (process.env.NODE_ENV !== 'production') {

    console.log("DEVELOPMENT VERSION:",version)
    
  
} else {
    console.log("PRODUCTION VERSION:",version)


    /*
    LogRocket.init('evmn92/tsv_enroll',{
        release: version.commit,
     });
    
    Raven.setDataCallback(function (data) {
        data.extra.sessionURL = LogRocket.sessionURL;
        return data;
    });
    */
   
    Raven.config('https://8213b9bc37a54cbcbd61616fc81f59e9@sentry.io/1263827',{
        release: version.commit,
        environment: process.env.NODE_ENV
    }).install();
    
}


const apolloClient = new ApolloClient({
    link: new HttpLink({ uri: '/client_graphql', credentials: 'same-origin'}),
    cache: new InMemoryCache({
        dataIdFromObject: object => {
            //console.log("dataIdFromObject",object)
            switch (object.__typename) {
                case 'Course': return object.key;
                default: return defaultDataIdFromObject(object);
            }
        }
    })
  });
  

let enroll_app = document.getElementById('enroll_app_embed')
if (enroll_app !== null) {
    console.log("render app");
    render( (
        <Provider store={store}>
            <PersistGate persistor={persistor}>
                <ApolloProvider client={apolloClient}>
                    <BrowserRouter>
                        <EnrollApp />
                    </BrowserRouter>
                </ApolloProvider>
            </PersistGate>
        </Provider>
    ), enroll_app );
}


console.log("reactbundle enroll done")