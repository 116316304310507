import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { compose } from 'react-apollo';
import version from './../../version.json';

const styles = theme => ({
    root: {
      marginTop: theme.spacing.unit * 3,
      width: '100%',
    },
    version: {
        margin:0,
        paddingRight: "10px",
        width: '100%',
        textAlign:'right',
        fontSize: "0.75rem"
    }
    
});



class Version extends React.Component {
 
    render() {
        const { classes,short } = this.props;
        if (short) {
            return (
                <div className={classes.version}>
                    {"v:"+version.commit}
                </div>
            )
        } else {
            return (
                <div className={classes.version}>
                    {version.log+ " ("+version.date+")"}
                </div>
            )
        }
    }
}



Version.propTypes = {
    classes: PropTypes.object.isRequired,
    short: PropTypes.bool
};
Version.defaultProps = {
    short:false
};


export default compose(
    withStyles(styles),
)(Version)